/* src/app/template/menu/menu.component.scss */
.offcanvas-start {
  opacity: 0;
  pointer-events: none;
  transform: translateX(-100%);
  transition: all 0.4s ease;
}
.offcanvas-start.show {
  opacity: 1;
  pointer-events: all;
  transform: translateX(0);
}
.offcanvas {
  top: 67px;
  z-index: 1050;
  background: #2b343a;
  width: 270px;
  background-image: url("./media/logoplusfrotamarca-TT7O7TG4.png");
  background-position: center 95%;
  background-size: 80%;
  background-repeat: no-repeat;
}
.mm-profile {
  background-color: #131f26;
  color: rgba(255, 255, 255, 0.6509803922);
}
.mm-profile.toggled > a:before {
  content: "\f278";
  transform: scale(0);
}
.mm-profile > a:before {
  content: "\f278";
  transform: scale(1);
}
.mm-profile > div:hover:first-child {
  background-color: rgba(255, 255, 255, 0.0392156863);
}
.mm-profile small {
  color: rgba(255, 255, 255, 0.3490196078);
}
.hover-white:hover,
.hover-white.active {
  color: white;
  font-weight: 500;
}
.hover-back:hover,
.hover-back.active {
  color: white;
  background-color: rgba(255, 255, 255, 0.0392156863);
}
.menu-item-color {
  color: rgba(255, 255, 255, 0.6509803922);
}
.sub-menu-item {
  color: #989898;
}
/*# sourceMappingURL=menu.component-PAVKX6FA.css.map */

<nav class="navbar navbar-expand-lg navbar-light bg-white">
  <div class="container-fluid">
    <button
      (click)="abrirMenu()"
      class="navbar-toggler d-block btn border border-0 pe-4"
      type="button"
      [ngClass]="{ collapsed: !showMenu }"
    >
      <span class="icon-bar top-bar"></span>
      <span class="icon-bar middle-bar"></span>
      <span class="icon-bar bottom-bar"></span>
    </button>
    <a class="navbar-brand" href="javascript:void(0)" [routerLink]="['/home']">
      <img src="assets/img/logoplusfrota.png" height="36" />
      &nbsp;&nbsp;&nbsp;&nbsp;
      <img
        [src]="cliente?.empresa?.logo_front"
        height="36"
        *ngIf="idempresaLogin != '' && cliente?.empresa?.logo_front"
      />
    </a>
    <div class="d-flex ms-auto">
      <ul class="navbar-nav align-items-center">
        <li *ngIf="nomeCC && nomeCC != ''" class="nav-item">
          Centro de Custo: {{ nomeCC }}
        </li>
        <li *ngIf="clientesCentralizadora.length > 0" class="nav-item me-2">
          Cliente:
        </li>
        <li *ngIf="clientesCentralizadora.length > 0" class="nav-item">
          <select
            class="form-select pointer"
            (ngModelChange)="trocarCliente($event)"
            [(ngModel)]="clienteSelecionado"
          >
            <option [value]="cliOriginalCodigo">{{ cliOriginalNome }}</option>
            <option
              *ngFor="let cli of clientesCentralizadora"
              [value]="cli.idcliente"
            >
              {{ cli.codtn }} - {{ cli.cpfcnpj | cgc }} -
              {{ cli.fantasia }}
            </option>
          </select>
        </li>
        <li *ngIf="credsCentralizadora.length > 0" class="nav-item me-2">
          Credenciado:
        </li>
        <li *ngIf="credsCentralizadora.length > 0" class="nav-item">
          <select
            class="form-select pointer"
            (ngModelChange)="trocarCred($event)"
            [(ngModel)]="credSelecionado"
          >
            <option [value]="credOriginalCodigo">{{ credOriginalNome }}</option>
            <option
              *ngFor="let cred of credsCentralizadora"
              [value]="cred.idcred"
            >
              {{ cred.codtn }} - {{ cred.cpfcnpj | cgc }} -
              {{ cred.nome_fantasia }}
            </option>
          </select>
        </li>
        <li class="nav-item" *ngIf="tipoUsuario != 4 && tipoUsuario != 5 && tipoUsuario != 6 ">
          <c-dropdown alignment="end">
            <a cDropdownToggle href="javascript:void(0)" title="Módulos">
              <i class="fa-sharp fa-solid fa-grid fs-5"></i>
            </a>
            <div
              class="rounded-0 shadow p-0"
              style="width: 300px"
              cDropdownMenu
            >
              <div class="d-flex flex-column ps-0 pt-0 pe-0 pb-2" cDropdownItem>
                <div class="border-bottom p-2 text-center">MÓDULOS</div>
                <div
                  class="d-flex flex-row align-items-center p-3 pointer hover"
                  [routerLink]="['/abastecimento']"
                  *ngIf="temAbastecimento"
                >
                  <i class="fa-solid fa-gas-pump fa-fw fs-5 mt-1"></i>
                  <div class="d-flex flex-column w-100 ps-3">
                    <span class="pb-1 fs-6 fw-light">Abastecimento</span>
                    <div class="progress" style="height: 5px">
                      <div
                        class="progress-bar w-100"
                        style="background-color: #f26522"
                      ></div>
                    </div>
                  </div>
                </div>
                <div
                  class="d-flex flex-row align-items-center p-3 pointer hover"
                  [routerLink]="['/manutencao']"
                  *ngIf="temManutencao"
                >
                  <i class="fa-solid fa-gears fa-fw fs-5 mt-1"></i>
                  <div class="d-flex flex-column w-100 ps-3">
                    <span class="pb-1 fs-6 fw-light">Manutenção</span>
                    <div class="progress" style="height: 5px">
                      <div class="progress-bar w-100"></div>
                    </div>
                  </div>
                </div>
                <div
                  class="d-flex flex-row align-items-center p-3 pointer hover"
                  [routerLink]="['/rastreamento']"
                  *ngIf="temRastreamento"
                >
                  <i class="fa-solid fa-location-dot fa-fw fs-5 mt-1"></i>
                  <div class="d-flex flex-column w-100 ps-3">
                    <span class="pb-1 fs-6 fw-light">Rastreamento</span>
                    <div class="progress" style="height: 5px">
                      <div class="progress-bar w-100 bg-danger"></div>
                    </div>
                  </div>
                </div>
                <div
                  class="d-flex flex-row align-items-center p-3 pointer hover"
                  [routerLink]="[
                    tipoUsuario == 3
                      ? '/notafiscal/nfnaorecolhida'
                      : '/notafiscal'
                  ]"
                  *ngIf="temNF"
                >
                  <i class="fa-solid fa-files fa-fw fs-5 mt-1"></i>
                  <div class="d-flex flex-column w-100 ps-3">
                    <span class="pb-1 fs-6 fw-light">Gestão NF</span>
                    <div class="progress" style="height: 5px">
                      <div class="progress-bar w-100 bg-secondary"></div>
                    </div>
                  </div>
                </div>
                <div
                  class="d-flex flex-row align-items-center p-3 pointer hover"
                  [routerLink]="['/valepedagio']"
                  *ngIf="temVale"
                >
                  <i class="fa-solid fa-road-circle-check fa-fw fs-5 mt-1"></i>
                  <div class="d-flex flex-column w-100 ps-3">
                    <span class="pb-1 fs-6 fw-light">Pedágio</span>
                    <div class="progress" style="height: 5px">
                      <div class="progress-bar w-100 bg-success"></div>
                    </div>
                  </div>
                </div>
                <div
                  class="d-flex flex-row align-items-center p-3 pointer hover"
                  [routerLink]="['/comunicacao']"
                  *ngIf="temComun"
                >
                  <i class="fa-solid fa-comments fa-fw fs-5 mt-1"></i>
                  <div class="d-flex flex-column w-100 ps-3">
                    <span class="pb-1 fs-6 fw-light">Comunicação</span>
                    <div class="progress" style="height: 5px">
                      <div class="progress-bar w-100 bg-purple"></div>
                    </div>
                  </div>
                </div>
                <div
                  class="d-flex flex-row align-items-center p-3 pointer hover"
                  [routerLink]="['/cursos']"
                  *ngIf="temCursos"
                >
                  <i class="fa-solid fa-graduation-cap fa-fw fs-5 mt-1"></i>
                  <div class="d-flex flex-column w-100 ps-3">
                    <span class="pb-1 fs-6 fw-light">Cursos</span>
                    <div class="progress" style="height: 5px">
                      <div class="progress-bar w-100 bg-dark"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </c-dropdown>
        </li>
        <li class="nav-item">
          <a (click)="logoff()" class="pointer" title="Sair">
            <i class="fa-sharp fa-solid fa-right-from-bracket fs-5"></i>
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>

<div class="offcanvas offcanvas-start" [ngClass]="{ show: showMenu }">
  <div class="offcanvas-header mm-profile flex-column p-0">
    <div
      class="d-flex justify-content-between align-items-center w-100 p-4 pointer"
      (click)="colUser.visible = !colUser.visible; colUser.toggle()"
    >
      <span>
        {{ nomeUser }}
        <br />
        <small>
          <span *ngIf="ClienteUser != '' && nomeUser != ClienteUser">
            {{ ClienteUser }}
            <br />
          </span>
          {{ perfilUser }}
        </small>
      </span>
      <i
        class="fa-solid fa-plus"
        [ngClass]="{ 'fa-plus': !colUser.visible, 'fa-minus': colUser.visible }"
      ></i>
    </div>
    <div class="w-100" #colUser="cCollapse" cCollapse>
      <div class="d-flex flex-column">
        <div
          class="pointer ps-5 pt-1 pb-3 hover-white"
          [ngClass]="{ active: router.isActive('/configuracoes', false) }"
          [routerLink]="['/configuracoes']"
          (click)="fecharMenu()"
        >
          Configurações
        </div>
        <div
          class="pointer ps-5 pt-1 pb-3 hover-white"
          [ngClass]="{ active: router.isActive('/conciliacaogood', false) }"
          [routerLink]="['/conciliacaogood']"
          (click)="fecharMenu()"
          *ngIf="idperfil == 2"
        >
          Conciliação Good
        </div>
        <div
          class="pointer ps-5 pt-1 pb-3 hover-white"
          [ngClass]="{ active: router.isActive('/parametros', false) }"
          [routerLink]="['/parametros']"
          (click)="fecharMenu()"
          *ngIf="idperfil == 2"
        >
          Parametros Sistema
        </div>
      </div>
    </div>
  </div>
  <div class="offcanvas-body text-white p-0">
    <div class="d-flex flex-column">
      <ng-container *ngFor="let men of menus">
        <div
          class="ps-4 py-3 pointer hover-back menu-item-color"
          *ngIf="!men.sub || men.sub.length == 0"
          [ngClass]="{
            active: router.isActive(men.tela, false)
          }"
          [routerLink]="[men.tela]"
          (click)="fecharMenu()"
        >
          <i [class]="'me-3 fa-solid ' + men.icon + ' fa-fw'"></i>
          {{ men.nome }}
        </div>

        <ng-container *ngIf="men.sub && men.sub.length > 0">
          <div
            class="sub-menu px-4 py-3 pointer hover-back menu-item-color d-flex justify-content-between align-items-center w-100"
            [ngClass]="{
              active: router.isActive(men.tela, false),
              toggled: subVar.visible
            }"
            (click)="subVar.visible = !subVar.visible; subVar.toggle()"
          >
            <span>
              <i [class]="'me-3 fa-solid ' + men.icon + ' fa-fw'"></i>
              {{ men.nome }}
            </span>
            <i
              class="fa-solid fa-plus"
              [ngClass]="{
                'fa-plus': !subVar.visible,
                'fa-minus': subVar.visible
              }"
            ></i>
          </div>
          <div class="w-100" #subVar="cCollapse" cCollapse>
            <div class="d-flex flex-column">
              <div
                *ngFor="let subs of men.sub"
                class="pointer ps-5 py-2 hover-white sub-menu-item"
                [ngClass]="{ active: router.isActive(subs.tela, false) }"
                [routerLink]="[subs.tela]"
                (click)="fecharMenu()"
              >
                {{ subs.nome }}
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>

<div
  (click)="fecharMenu()"
  class="offcanvas-backdrop fade show"
  *ngIf="showMenu"
></div>

/* src/app/template/header/header.component.scss */
.navbar-toggler span {
  display: block;
  background-color: #4f4f4f;
  height: 3px;
  width: 25px;
  margin-top: 5px;
  margin-bottom: 5px;
  position: relative;
  left: 0;
  opacity: 1;
  transition: all 0.35s ease-out;
  transform-origin: center left;
}
.navbar-toggler span:nth-child(1) {
  transform: translate(0%, 0%) rotate(0deg);
}
.navbar-toggler span:nth-child(2) {
  opacity: 1;
}
.navbar-toggler span:nth-child(3) {
  transform: translate(0%, 0%) rotate(0deg);
}
.navbar-toggler span:nth-child(1) {
  margin-top: 0.3em;
}
.navbar-toggler:not(.collapsed) span:nth-child(1) {
  transform: translate(15%, -33%) rotate(45deg);
}
.navbar-toggler:not(.collapsed) span:nth-child(2) {
  opacity: 0;
}
.navbar-toggler:not(.collapsed) span:nth-child(3) {
  transform: translate(15%, 33%) rotate(-45deg);
}
.navbar {
  border-bottom: 5px solid #1e262b;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3019607843);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
}
.dropdown-toggle:after {
  content: none;
}
nav li > c-dropdown > a,
nav li > a {
  color: #5e5e5e;
  display: block;
  text-align: center;
  z-index: 1;
  position: relative;
  transition: background-color;
  transition-duration: 0.25s;
  border-radius: 2px;
  padding: 5px 15px 3px;
}
nav li > c-dropdown > a::after,
nav li > a::after {
  content: none !important;
}
nav li.toggled > c-dropdown > a,
nav li:hover > c-dropdown > a,
nav li.toggled > a,
nav li:hover > a {
  background-color: rgba(0, 0, 0, 0.0784313725);
}
.hover:hover {
  background-color: #f7f7f7;
}
.dropdown-item:focus,
.dropdown-item:hover {
  background-color: white;
}
nav {
  z-index: 1049;
}
/*# sourceMappingURL=header.component-EOX6MCGH.css.map */

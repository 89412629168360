<div #splashScreen class="m-splash-screen">
  <mat-spinner diameter="40" style="margin-bottom: 20px"></mat-spinner>
  <img
    src="../assets/img/logoplusfrota.png"
    style="width: 230px; margin-bottom: 20px"
    alt="Logo"
  />
  Aguarde, carregando os módulos da melhor solução para gestão da sua frota
</div>

<div class="m-splash-screen" *ngIf="loading && !erro">
  <mat-spinner diameter="40" style="margin-bottom: 20px"></mat-spinner>
  <img
    src="../assets/img/logoplusfrota.png"
    style="width: 230px; margin-bottom: 20px"
    alt="Logo"
  />
  Aguarde, carregando...
</div>

<div class="m-splash-screen" *ngIf="erro">
  <img
    src="../assets/img/logoplusfrota.png"
    style="width: 230px; margin-bottom: 20px"
    alt="Logo"
  />
  Não foi possivel encontrar a pagina solicitada
</div>

<div [hidden]="loading">
  <router-outlet></router-outlet>
</div>
